<script>
  import Counter from './components/Counter.svelte';

  const urlParams = new URLSearchParams(window.location.search);
  const birthday = urlParams.get('birthday') || '2010-04-02T03:47+02:00';
  const dateOfBirth = Date.parse(birthday);
</script>

<main
  class="flex h-screen w-screen items-center justify-center bg-slate-100 dark:bg-slate-900"
>
  <Counter {dateOfBirth} />
</main>
