<script>
  import dayjs from 'dayjs';

  export let dateOfBirth;

  let now = dayjs(),
    years,
    decimals;

  $: {
    [years, decimals] = now
      .diff(dateOfBirth, 'year', true)
      .toFixed(10)
      .split('.');
  }

  setInterval(() => {
    now = dayjs();
  }, 100);
</script>

<dl class="font-medium uppercase">
  <dt
    class="pl-1 text-xl tracking-wider text-gray-400 md:pl-2 md:text-3xl dark:text-gray-600"
  >
    Age
  </dt>

  <dd class="text-gray-900 dark:text-gray-500">
    <span class="text-7xl font-bold md:text-9xl">
      {years}
    </span>
    <span class="text-3xl tabular-nums md:text-6xl">
      ,{decimals}
    </span>
  </dd>
</dl>
